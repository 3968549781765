
import {defineComponent, ref, watch} from 'vue'
import BaseForm from "@/components/base/form/BaseForm.vue";
import Froala from "@/components/base/editor/Froala.vue";
import BaseRadio from "@/components/base/form/BaseRadio.vue";
import ClaimService from "@/core/services/ClaimService";

export default defineComponent({
  name: "GHIPStatusForm",
  components: {BaseRadio, Froala, BaseForm},
  props: {
    claimId: {type: String, required: true},
    data: {type: Object},
  },
  emits: ['saved'],
  setup(props) {
    const canComplete = ref(false);
    const model = ref(props.data);
    watch(() => props.data, cb => {
      model.value = cb
    })
    const check =(id)=>{
      ClaimService.checkGhipComplete(id).then(res=>{
        canComplete.value = res.result
      })
    }
    check(props.claimId)
    watch(()=>props.claimId, cb=>{
      check(cb);
    })
    const submitting = ref(false);
    return {
      submitting,
      model,
      canComplete,
    }
  },
  methods: {
    submit() {
      this.submitting = true;
      ClaimService.updateGhipStatus(this.claimId, this.model).then(res => {
        this.$emit('saved', res)
      }).finally(() => {
        this.submitting = false;
      })
    }
  }
})
