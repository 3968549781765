
import {defineComponent, ref} from "vue";
import {hideModal, showModal} from "@/core/helpers/dom";

export default defineComponent({
  name: "BaseModal",
  props: {
    name: {required: true, type: String},
    id: {required: true, type: String, default: 'tr_modal_add'},
    size: {required: false, type: String, default: 'mw-750px'},
  },
  emits: ['onHide'],
  setup(_, {emit}) {
    const baseModalRef = ref<null | HTMLElement>(null)
    const hideBaseModal = () => {
      hideModal(baseModalRef.value)
      emit('onHide')
    }
    const showBaseModal = () => {
      showModal(baseModalRef.value)
    }
    return {
      baseModalRef,
      hideBaseModal,
      showBaseModal,
    }
  }
})
