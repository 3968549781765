
import {defineComponent, ref, watch} from 'vue'
import BaseForm from "@/components/base/form/BaseForm.vue";
import {Field} from "vee-validate";
import {GHIP_PROVINCES} from "@/core/config/Constant";
import BaseSelect from "@/components/base/select/BaseSelect.vue";
import ClaimService from "@/core/services/ClaimService";

export default defineComponent({
  name: "GHIPDetailForm",
  components: {BaseSelect, BaseForm, Field},
  props: {
    claimId: {type: String, required: true},
    data: {type: Object},
  },
  emits: ['saved'],
  setup(props) {
    const submitting = ref(false);
    const model = ref(props.data);
    watch(() => props.data, cb => {
      model.value = cb;
    })
    return {
      model,
      submitting,
      GHIP_PROVINCES,
    }
  },
  methods: {
    submit() {
      this.submitting = true;
      ClaimService.updateGhipDetail(this.claimId, this.model).then(res => {
        this.$emit('saved', res)
      }).finally(() => {
        this.submitting = false;
      })
    }
  }
})
