
import { defineComponent, ref, watch } from "vue";

export default defineComponent( {
  name: "BaseRadio",
  // components: {Field},
  props: {
    modelValue: {},
    name: {type:String, default: 'caseType'},
    data: {type: Array},
    disabled: {default: false}
  },
  emits: ['update:modelValue', 'changeValue'],
  setup(props, {emit}) {
    const model = ref(props.modelValue)
    watch(()=>props.modelValue, (cb)=>{
      model.value = cb;
    })
    const onChangeCheckInput = (event) =>{
      emit('update:modelValue',event.target.value)
      emit('changeValue',event.target.value)
    }
    return {
      model,
      onChangeCheckInput,
    }
  }
})
