
import {computed, defineComponent} from 'vue'
import store from "@/store";
import QuickAction from "@/components/base/action/QuickAction.vue";
import BaseModal from "@/components/base/modal/BaseModal.vue";
import Row from "@/components/base/common/Row.vue";
import GHIPStatusLabel from "@/views/claim/recovery/GHIPStatusLabel.vue";
import GHIPStatusForm from "@/views/claim/recovery/GHIPStatusForm.vue";
import {Actions} from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "GHIPStatus",
  components: {GHIPStatusForm, BaseModal, GHIPStatusLabel, Row, QuickAction},
  setup() {
    const claim = computed(() => store.state.ClaimModule.claim);

    return {
      claim,
    }
  },
  methods: {
    onUpdateStatus() {
      const modal = this.$refs.updateGHIPStatusRef as typeof BaseModal;
      modal.showBaseModal();

    },
    onSaved() {
      const modal = this.$refs.updateGHIPStatusRef as typeof BaseModal;
      store.dispatch(Actions.LOAD_CLAIM, this.claim.id)
      modal.hideBaseModal();
    }
  }
})
