import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-md-7" }
const _hoisted_3 = { class: "col-md-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GHIPDetail = _resolveComponent("GHIPDetail")!
  const _component_GHIPStatus = _resolveComponent("GHIPStatus")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_GHIPDetail)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_GHIPStatus)
    ])
  ]))
}