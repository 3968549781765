
import { defineComponent } from "vue";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";

export default defineComponent({
  name: "Row",
  components: {DateTimeFormat},
  props: {
    row: {type: Array, required: true}
  }
})
