
import {computed, defineComponent} from 'vue'
import store from "@/store";
import Row from "@/components/base/common/Row.vue";
import QuickAction from "@/components/base/action/QuickAction.vue";
import BaseModal from "@/components/base/modal/BaseModal.vue";
import GHIPDetailForm from "@/views/claim/recovery/GHIPDetailForm.vue";
import {Actions} from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "GHIPDetail",
  components: {GHIPDetailForm, BaseModal, QuickAction, Row},
  setup() {
    const claim = computed(() => store.state.ClaimModule.claim);
    return {
      claim,
    }
  },
  methods: {
    onUpdateGhip() {
      const modal = this.$refs.ghipDetail as typeof BaseModal;
      modal.showBaseModal();
    },
    onSaved() {
      const modal = this.$refs.ghipDetail as typeof BaseModal;
      store.dispatch(Actions.LOAD_CLAIM, this.claim.id)
      modal.hideBaseModal();
    }
  }
})
