
import {defineComponent, ref} from "vue";
import {Form} from 'vee-validate';
import store from "@/store";

export default defineComponent({
  name: "BaseForm",
  components: {Form},
  props: {
    validate: {},
    submitting: {required: false, default: false, type: Boolean},
    disableSubmit: {default: false, type: Boolean},
    submitButtonName: {default: 'Save', type: String},
    defaultSubmit: {type: Boolean, default: true}
  },
  emits: ['form-discard', 'form-submit'],
  setup(_, {emit}) {
    const baseFormRef = ref<HTMLFormElement | null>(null);
    const formDiscard = () => {
      emit('form-discard')
    }
    const formSubmit = () => {
      emit('form-submit')
    }
    store.subscribe((mutation, state) => {
      if (mutation.type === 'setFormError') {
        baseFormRef.value?.setErrors(state.ValidationModule.errors)
      }
    });
    return {
      formSubmit,
      formDiscard,
      baseFormRef,
    }
  },
  methods: {
    addErrors(errors) {
      this.baseFormRef?.setErrors(errors)
    }
  }
})
